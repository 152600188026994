<template>
  <div class="realNameAuditForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :footerShow="!isView"
      @update="update"
    >
      <el-form-item label="手机号码" prop="mobile">
        <span>{{ form.mobile }}</span>
      </el-form-item>

      <el-form-item label="用户昵称" prop="nickName">
        <span>{{ form.nickName }}</span>
      </el-form-item>

      <el-form-item label="性别" prop="gender">
        <span>{{ sexMap[form.gender] }}</span>
      </el-form-item>

      <el-form-item label="真实姓名" prop="realName">
        <span>{{ form.realName }}</span>
      </el-form-item>

      <el-form-item label="身份证件" prop="idCardType">
        <span>{{ form.idCardType }}</span>
      </el-form-item>

      <el-form-item label="证件号码" prop="idCardNum">
        <span>{{ form.idCardNum }}</span>
      </el-form-item>

      <el-form-item label="证件图片">
        <v-upload multiple :disabled="true" :limit="9" :imgUrls="imgUrls" />
      </el-form-item>

      <el-form-item
        label="审核状态"
        :rules="[
          { required: true, message: '请选择审核状态', trigger: 'blur' },
        ]"
        prop="auditStatus"
      >
        <v-select
          v-if="!isView"
          :options="auditStatusOps"
          v-model="form.auditStatus"
        />
        <span v-else>{{ auditStatusMap[form.auditStatus] }}</span>
      </el-form-item>

      <el-form-item
        label="备注"
        prop="auditRemarks"
        :rules="[
          {
            required: form.auditStatus === 2,
            message: '请填写备注',
            trigger: 'blur',
          },
        ]"
      >
        <v-input
          v-if="!isView"
          type="textarea"
          placeholder="请输入备注"
          v-model="form.auditRemarks"
          :width="520"
          :maxlength="200"
        ></v-input>
        <span v-else>{{ form.auditRemarks }}</span>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { getRealnameAuditDetailURL, examineRealnameAuditURL } from "./api.js";
import { auditStatusMap, setAuditStatusOps, sexMap, setSexOps } from "./map.js";

export default {
  name: "placeEdit",
  data() {
    return {
      width: 250,
      uploadURL: "",
      submitConfig: {
        queryUrl: getRealnameAuditDetailURL,
        submitUrl: examineRealnameAuditURL,
      },
      auditStatusMap,
      auditStatusOps: setAuditStatusOps().slice(1),
      sexMap,
      sexOps: setSexOps(2),
      isView: true,
      form: {
        id: "",
        mobile: "",
        nickName: "",
        realName: "",
        gender: "",
        idCardType: "",
        idCardNum: "",
        idCardPhotoFront: "",
        idCardPhotoBack: "",
        auditStatus: undefined,
        auditRemarks: "",
        userId: "",
      },
    };
  },
  computed: {
    imgUrls() {
      if (this.form.idCardPhotoFront && this.form.idCardPhotoBack) {
        return `${this.form.idCardPhotoFront},${this.form.idCardPhotoBack}`;
      } else if (this.form.idCardPhotoFront || this.form.idCardPhotoBack) {
        return `${this.form.idCardPhotoFront || this.form.idCardPhotoBack}`;
      } else if (!this.form.idCardPhotoFront && !this.form.idCardPhotoBack) {
        return "";
      }
      return "";
    },
  },
  mounted() {
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      if (data.auditStatus === 2) {
        this.isView = true;
      } else {
        this.isView = false;
      }
      this.form.auditStatus =
        data.auditStatus === 0 ? undefined : data.auditStatus;
    },
    submitBefore() {
      return {
        id: this.form.id,
        auditStatus: this.form.auditStatus,
        auditRemarks: this.form.auditRemarks,
        auditUser: this.$store.state.app.userInfo.id,
        userId: this.form.userId,
      };
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.realNameAuditForm {
  box-sizing: border-box;
  height: 100%;
}
</style>
