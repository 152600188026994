var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"realNameAuditForm"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess,"footerShow":!_vm.isView},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"手机号码","prop":"mobile"}},[_c('span',[_vm._v(_vm._s(_vm.form.mobile))])]),_c('el-form-item',{attrs:{"label":"用户昵称","prop":"nickName"}},[_c('span',[_vm._v(_vm._s(_vm.form.nickName))])]),_c('el-form-item',{attrs:{"label":"性别","prop":"gender"}},[_c('span',[_vm._v(_vm._s(_vm.sexMap[_vm.form.gender]))])]),_c('el-form-item',{attrs:{"label":"真实姓名","prop":"realName"}},[_c('span',[_vm._v(_vm._s(_vm.form.realName))])]),_c('el-form-item',{attrs:{"label":"身份证件","prop":"idCardType"}},[_c('span',[_vm._v(_vm._s(_vm.form.idCardType))])]),_c('el-form-item',{attrs:{"label":"证件号码","prop":"idCardNum"}},[_c('span',[_vm._v(_vm._s(_vm.form.idCardNum))])]),_c('el-form-item',{attrs:{"label":"证件图片"}},[_c('v-upload',{attrs:{"multiple":"","disabled":true,"limit":9,"imgUrls":_vm.imgUrls}})],1),_c('el-form-item',{attrs:{"label":"审核状态","rules":[
          { required: true, message: '请选择审核状态', trigger: 'blur' },
        ],"prop":"auditStatus"}},[(!_vm.isView)?_c('v-select',{attrs:{"options":_vm.auditStatusOps},model:{value:(_vm.form.auditStatus),callback:function ($$v) {_vm.$set(_vm.form, "auditStatus", $$v)},expression:"form.auditStatus"}}):_c('span',[_vm._v(_vm._s(_vm.auditStatusMap[_vm.form.auditStatus]))])],1),_c('el-form-item',{attrs:{"label":"备注","prop":"auditRemarks","rules":[
          {
            required: _vm.form.auditStatus === 2,
            message: '请填写备注',
            trigger: 'blur',
          },
        ]}},[(!_vm.isView)?_c('v-input',{attrs:{"type":"textarea","placeholder":"请输入备注","width":520,"maxlength":200},model:{value:(_vm.form.auditRemarks),callback:function ($$v) {_vm.$set(_vm.form, "auditRemarks", $$v)},expression:"form.auditRemarks"}}):_c('span',[_vm._v(_vm._s(_vm.form.auditRemarks))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }